import { React, useState, useEffect } from 'react';
import { Link, useHistory, useLocation } from "react-router-dom";

import Typography from '@mui/joy/Typography';
import { TextareaAutosize } from '@mui/base';
import Stack from '@mui/joy/Stack';

import { getDocumentTypes } from "../api/documentTypes"
import BusySpinner from './common/BusySpinner';
import  { SelectFileComponent } from "./common/UploadFiles";
import SchoolDocumentType from "./common/SchoolDocumentType";
import { appendToFormData, callUploadApi } from "../api/uploadDocuments";
import { SelectStateEnum } from './common/UploadFileSelectorEnum';
import { number0, number1, number2, number3, number4, number5, number5p0, number0p0 } from "../utils/constants";
import { uploadURL } from "../utils/constants";

const ProspectSchoolOfficialPage = () =>  {
  
  const h = useHistory();
  const loc = useLocation();

  const [submitBtnDisabled, setSubmitBtnDisabled] = useState(false);
  const [isValidData, setIsValidData] = useState(false);
  const [submitErrors, setSubmitErrors] = useState('');
  const [uploadFiles, setUploadFiles] = useState(Array.from({length: number5}).fill(
    {
      uploadid: 0, 
      filetoupload: null, 
      doctype: '', 
      errors: {doctype: "", fileselected: ""}
    }
  )
  );

  /* Component state separate from selected file object since the rerender on state change is controlled by the parent (this page) */
  const [uploadComponentStates, setUploadComponentStates] = useState(Array.from({length: number5}).fill(SelectStateEnum.Select));
  
  const [documentTypes, setDocumentTypes] = useState([]);
  const [totalFileSize, setTotalFileSize] = useState(0.0);
  const [comments, setComments] = useState("");
  const [busy, setBusy] = useState({'display': 'none'});
  
    // Load the userTypes and set the To attribute on Link component
    useEffect(() => {
        setDocumentTypes(getDocumentTypes("prospect"));
    }, []);

    
    // Call API to upload documnent(s)
    useEffect(() => {
 
      if (isValidData) {
        
        setBusy({'display': 'inline-block'});

        const formData = appendToFormData(uploadURL,loc.state, uploadFiles, comments);
        callUploadApi(formData)
          .then (result => {
          
          h.push("/uploadresult", {state: {uploadresult: "success", usertype: "prospect", apiresult: result}}); 
        })
        .catch(error => {
          
          h.push("/uploadresult", {state: {uploadresult: "failure", usertype: "prospect", apierror: error}}); 
        })
        .finally(() => {
          setSubmitBtnDisabled(false);
          setBusy({'display': 'none'});
        });

      }

  
    }, [isValidData]);


    const fileSelectorErrors = () => {

      let fileSelectorHasErrors = false;
  
      uploadFiles.forEach(uploadFile => {
        if (uploadFile.errors.doctype.length > 0 || uploadFile.errors.fileselected.length > 0) {
          fileSelectorHasErrors = true;
        }       
      });
  
      return fileSelectorHasErrors;
    };


    const calculateTotalFileSize = (_uploadFiles) => {
      let totalFileSizeMB = 0.0;
      const number1000000=1000000;

      _uploadFiles.forEach(uploadFile => {
        if (uploadFile.filetoupload){
          totalFileSizeMB += (uploadFile.filetoupload.size / number1000000);
        }
         
      });

      // return only 1 digit after decimal point
      return (+totalFileSizeMB.toFixed(number2));
    };


    const formIsValid  = () => {
      
      if (fileSelectorErrors()) {
        setSubmitErrors('Please correct the errors on the form before clicking Submit.' );
        return false;
      }

      if (totalFileSize > number5p0) {
        setSubmitErrors('Total size of all files selected for upload cannot be greater than 5.0MB');
        return false;
      }

      if (totalFileSize === number0p0) {      
        setSubmitErrors('Please choose at least one file to upload before clicking Submit.');
        return false;
      }

      setIsValidData(true);
      return true;
  };

  const handleSubmit = event => {

      event.preventDefault();

      if (!formIsValid()) {
        return;
      }

      setSubmitBtnDisabled(true);

  };


  const handleUploadFileSelected = (uploadFile, componentState) => {

    setSubmitErrors('');

    // Set upload files. If no selected file (user removed selection), no point having that item in array.
    // Note that the object is being set to null in this case. 
    // This is not the same as 'empty' which is when a user does not even select a file.
    const idx = uploadFile.uploadid - number1;
    
    // store the state passed from child component
    const _uploadComponentStates = [...uploadComponentStates];
    _uploadComponentStates[idx] = componentState;
    setUploadComponentStates(_uploadComponentStates);

    // store file selected (if any) and doctype selected in child component
    const _uploadFiles = [...uploadFiles]
    _uploadFiles[idx] = uploadFile;
    setUploadFiles(_uploadFiles);

    // Calculate and set total file size for selected files. Note: even though uploadFiles will be the same array, 
    // objects within the array (fileselected object) will have changed. 
    // The calculateTotalFileSize() method will correctly calculate the total size.
    setTotalFileSize(calculateTotalFileSize(_uploadFiles));

  };


  const handleRemoveSelectedFile = (uploadFile, componentState) => {

    const idx = uploadFile.uploadid - number1;

    // store the state passed from child component
    const _uploadComponentStates = [...uploadComponentStates];
    _uploadComponentStates[idx] = componentState;
    setUploadComponentStates(_uploadComponentStates);

    // Reset object
    const _uploadFiles = [...uploadFiles]
    uploadFile.doctype = '';
    uploadFile.filetoupload = null;
    _uploadFiles[idx] = uploadFile;

    setUploadFiles(_uploadFiles);

    // Calculate and set total file size for selected files. Note: even though uploadFiles will be the same array, 
    // objects within the array (fileselected object) will have changed. 
    // The calculateTotalFileSize() method will correctly calculate the total size.
    setTotalFileSize(calculateTotalFileSize(_uploadFiles));

  };


  const onTextInputChange = event => {
    event.preventDefault();
    const textValue = event.target.value;
    setComments(textValue);
  };


  return (
      <>
        <h1>Secure Document Upload</h1>
        <p>Please upload your documents from the list below and select the document type.</p>
        <h3>Document types for prospect schools</h3>
        
        <SchoolDocumentType usertype="prospect" />

        <p></p>
        <h3>Add documents</h3>

        <Typography>
          Documents must be in an image format such as JPEG, TIFF, or PDF. You can submit up to five documents at a 
          time but the combined size of all images being sent can't exceed 5.0MB.
        </Typography>

        <p></p>        
        <label ><b>{totalFileSize}</b> of 5.0MB used</label>
        <p></p>
        <Stack spacing={2}>
          <SelectFileComponent 
                                    uploadfile={{...uploadFiles[number0], uploadid: number1}}
                                    componentstate= {uploadComponentStates[number0]}
                                    documenttypes={documentTypes} 
                                    onUploadFileSelected={handleUploadFileSelected} 
                                    onRemoveSelectedFile={handleRemoveSelectedFile}
                />
                <SelectFileComponent 
                                    uploadfile={{...uploadFiles[number1], uploadid: number2}} 
                                    componentstate= {uploadComponentStates[number1]}
                                    documenttypes={documentTypes} 
                                    onUploadFileSelected={handleUploadFileSelected}
                                    onRemoveSelectedFile={handleRemoveSelectedFile}
                />
                <SelectFileComponent 
                                    uploadfile={{...uploadFiles[number2], uploadid: number3}}
                                    componentstate= {uploadComponentStates[number2]}                                    
                                    documenttypes={documentTypes} 
                                    onUploadFileSelected={handleUploadFileSelected}
                                    onRemoveSelectedFile={handleRemoveSelectedFile}
                />
                <SelectFileComponent 
                                    uploadfile={{...uploadFiles[number3], uploadid: number4}} 
                                    componentstate= {uploadComponentStates[number3]}
                                    documenttypes={documentTypes} 
                                    onUploadFileSelected={handleUploadFileSelected}
                                    onRemoveSelectedFile={handleRemoveSelectedFile}
                />
                <SelectFileComponent 
                                    uploadfile={{...uploadFiles[number4], uploadid: number5}} 
                                    componentstate= {uploadComponentStates[number4]}
                                    documenttypes={documentTypes} 
                                    onUploadFileSelected={handleUploadFileSelected}
                                    onRemoveSelectedFile={handleRemoveSelectedFile}
                />
          </Stack>

          <h3>Comments</h3>

          <Typography>
            Please add any comments to explain the documents you're sending and why you're sending them. When you're
            finished, select the <b>Submit</b> button to forward your documents
          </Typography>

          <p></p>
          
          <TextareaAutosize
            style={{ width: 600 }}
            multiline="true"
            minRows={4}
            id="comments"
            type="text"
            placeholder="Comments"
            label=""
            variant="outlined"
            size="sm" 
            onChange={onTextInputChange}      
          />
        <div className= "form-group" align={"left"} style={{'display': 'flex', 'flexDirection': 'row', 'alignSelf': 'flex-start', padding: '60px 0px'}}>   
          <Link to={ 
                     {
                        pathname: "/schoolofficialinfo/prospect",
                        state: {schoolinfo: loc.state.schoolinfo, schoolofficialinfo: loc.state.schoolofficialinfo}
                     }
                }     
                className="btn custom-btn-secondary">
              Back
          </Link>
          <button className="btn custom-btn-primary" name="submitbtn" disabled={submitBtnDisabled} onClick={handleSubmit}>Submit</button>  
          <BusySpinner busy={busy} />       
        </div>
        { (submitErrors.length > 0) && <div className="alert alert-danger">{submitErrors}</div> }    
      </>
    );

  }

export default ProspectSchoolOfficialPage;
