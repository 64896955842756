import sdu_env from '../config.js';

const number0=0;
const number1 = 1;
const number2 = 2;
const number3 = 3;
const number4 = 4;
const number5 = 5;

const number5p0=5.0;
const number0p0 = 0.0;

const uploadURL = sdu_env.UPLOAD_API_URL;

export { number0, number1, number2, number3, number4, number5, number5p0, number0p0, uploadURL };