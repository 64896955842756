import React from "react";
import './App.css';

import Typography from '@mui/joy/Typography';
import Container from "@mui/material/Container";
import { Link as TextLink } from '@mui/joy';

const Footer = () => {

  return (
    <div className="footer">
      <Container maxWidth="lg">

          <Typography>
            <br/>
            <br/>
            ©{new Date().getFullYear()} Sallie Mae Bank. All rights reserved. Sallie Mae, the Sallie Mae logo, and other Sallie Mae names and logos are service marks or registered service marks of Sallie Mae Bank. All other names and logos used are the trademarks or service marks of their respective owners.
            <br/>
            <TextLink href="https://www.salliemae.com/legal/terms/" target="_blank" rel="noopener noreferrer">
              Terms of use
            </TextLink> |
            <TextLink href="https://www.salliemae.com/legal/privacy/" target="_blank" rel="noopener noreferrer">
              Protecting your privacy
            </TextLink> |
            <TextLink href= "https://www.salliemae.com/legal/avoid-fraud/" target="_blank" rel="noopener noreferrer">
              Avoid online fraud
            </TextLink>
            <br/>
            SLM Corporation and its subsidiaries, including Sallie Mae Bank, are not sponsored by or agencies of the United States of America.
            <br/>
            <br/>
            <br/>
          </Typography>

      </Container>
    </div>
  );
};

export default Footer;
