export const getDocumentTypes = userType => {

    const existingUserDocTypes = [
        { label: 'Accreditation Certificate', value: 'ExiAcc0001', id: 1, description:"Document validating school's ability to provide instruction" },
        { label: 'ACH Verification Approval', value: 'ExiACH0001', id: 2, description: "ACH verification approval memo from Western Union customers allowing written bank account verification due to time zone differences." },
        { label: 'Audited Financial/Tax Returns', value: 'ExiAud0001', id: 3, description: "Most recent three years of comparative, audited GAAP Financials."},
        { label: 'Department of Education Program Participation Agreement (PPA)', value: 'ExiDep0001', id: 4, description: "School documentation from a licensing or accrediting agency required by Quality Assurance for proof of For-Profit name change."},
        { label: 'Documentation related to a school name change', value: 'ExiDoc0001', id: 5, description: "School documentation from a licensing or accrediting agency required by Quality Assurance for proof of For-Profit name change."},
        { label: 'ECAR', value: 'ExiECA0001', id: 6, description: "Department of Ed issued reports evidencing Title IV eligibility"},
        { label: 'EFT Authorization Form', value: 'ExiEFT0001', id: 7, description: "Schools' Bank account detail for electronic disbursement"},
        { label: 'License', value: 'ExiLic0001', id: 8, description: "Document evidencing the school's ability to conduct business as educational institution in their states(s)"},
        { label: 'Loan Program and Application Processing Agreement', value: 'ExiAPA0001', id: 9, description: "Contract governing school/SLM roles and responsibilities for processing"},        
        { label: 'NFP/FP Survey', value: 'ExiNFP0001', id: 10, description: "Survey completed by university customers that have relationship with For-Profit entity to help deliver education."},
        { label: 'Return Manifest Form', value: 'ExiRet0001', id: 11, description: "Document accessible on Open Net which allows schools to return funds"},
        { label: 'School Certification', value: 'ExiSch0002', id: 12, description: "Paper School Certification"},
        { label: 'School Contact Update Request', value: 'ExiSch0001', id: 13, description: "Method by which schools will update key financial aid contacts"},
        { label: 'Wire Instructions', value: 'ExiWir0001', id: 14, description: "International school customers may provide back-up documentation to support a wire sent to Sallie Mae for a return of funds.  The document is generally a copy of the statement from the bank initiating the wire."}

    ];
    
    const prospectUserDocTypes = [
        { label: 'Accreditation Certificate', value: 'ProAcc0001', id: 1, description: "Document validating school's ability to provide instruction"},
        { label: 'Audited Financial/Tax Returns', value: 'ProAud0001', id: 2, description: "Most recent three years of comparative, audited GAAP Financials."},
        { label: 'Audited Outcomes Report/C.I.R.R Report for bootcamps', value: 'ProAud0002', id: 3, description: "CIRR (Council on Integrity of Results in Reporting) outcomes report or other audited report confirming student outcomes (Bootcamps only)"},
        { label: 'Cost of Attendance', value: 'ProCos0001', id: 4, description: "School documentation confirming annual cost of attendance"},
        { label: 'Eligibility and Certification Approval Report (ECAR)', value: 'ProEli0001', id:5, description: "Department of Ed issued reports evidencing Title IV eligibility"},
        { label: 'Eligibility Questionnaire', value: 'ProEli0002', id: 6, description: "Sallie Mae intake form collecting required school information"},
        { label: 'Enrollment Agreement(s)', value: 'ProEnr0001', id: 7, description: "Contract between student and school governing terms of enrollment"},
        { label: 'FAA License', value: 'ProFAA0001', id: 8, description: "Federal Aviation Agency certificate (flight schools only)"},
        { label: 'Graduation and Placement Rates', value: 'ProGra0001', id: 9, description: "Documentation detailing the school's graduation and placement rates."},
        { label: 'Guaranty Agreement', value: 'ProGua0001', id: 10, description: "Legal agreement sent by a prospective school customer guaranteeing certain aspects of the customer relationship."},
        { label: 'License', value: 'ProLic0001', id: 11, description: "Document evidencing the school's ability to conduct business as educational institution in their states(s)"},
        { label: 'Loan Program and Application Processing Agreement', value: 'ProApp0001', id: 12, description: "Contract governing school/SLM roles and responsibilities for processing"},
        { label: 'NFP/FP Survey', value: 'ProNFP0001', id: 13, description: "Survey completed by university customers that have relationship with For-Profit entity to help deliver education."},
        { label: 'Proof of NFP Status', value: 'ProPro0001', id: 14, description: "Documentation to confirm school's Not-for-Profit status."},
        { label: 'Refund Policy', value: 'ProRef0001', id: 15, description: "School policy on refund % relative to withdraw dates"},
        { label: 'Sallie Mae Flight School Guidelines', value: 'ProSal0001', id: 16, description: "School responses to flight school guidelines"},
        { label: 'School Catalog', value: 'ProSch0001', id: 17, description: "School catalog outlining course descriptions and other information required for Quality Assurance verification process for a For-Profit school."},
        { label: 'Other', value: 'ProOth0001', id: 18, description: "Occassionally we ask the school to send org charts or similar docs."}
    ];

    if (userType === 'existing'){
        return existingUserDocTypes;
    }
        
    else if (userType === 'prospect'){
        return prospectUserDocTypes;
    }
        
    else {
        return [];  // empty array
    }
        
        
}