const callUploadApi = async formData => {
    const response = await fetch(formData[1], {
        method: 'POST',
        mode: 'cors',
        catch: 'no-cache',
        credentials: 'same-origin',
        redirect: 'follow',
        referrerPolicy: 'no-referrer',
        body: formData[0]
    });

    if (response.ok) {
        return response.json(); // parses JSON response into native JavaScript objects    
    }

    const errorMessage = await response.text();
    throw new Error(errorMessage);

}


const appendToFormData = (uploadApiUrl, uploadInfo, uploadFiles, comments) => {
   

    const formData = new FormData();
    for (let i=0;i< uploadFiles.length; i++) {

        if (uploadFiles[i].filetoupload) {
            const filename = 'UploadFileName' + i;
            formData.append(filename, uploadFiles[i].filetoupload);
            formData.append('LoanDocumentCategoryCode', uploadFiles[i].doctype);
        }
    }
    formData.append('Comments', comments);
    
    formData.append('FirstName', uploadInfo.schoolofficialinfo.firstname);
    formData.append('LastName', uploadInfo.schoolofficialinfo.lastname);
    formData.append('PhoneNumber', uploadInfo.schoolofficialinfo.phonenumber);
    formData.append('EmailAddress', uploadInfo.schoolofficialinfo.emailaddress);

    formData.append('CLUID', uploadInfo.schoolinfo.cluid);
    formData.append('SchoolMailAddress', uploadInfo.schoolinfo.schooladdress);
    formData.append('SchoolName', uploadInfo.schoolinfo.schoolname);
    formData.append('SchoolCode', uploadInfo.schoolinfo.schoolcode);
    formData.append('SchoolType', uploadInfo.schoolinfo.usertype);
    
    
   
    return [formData, uploadApiUrl];
}

export {callUploadApi, appendToFormData};
