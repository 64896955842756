export const getDepositDocumentTypes = () => {
    const depositDocTypes = [
        { label: 'Application documents', value: 'BanApp0001', id: 1 },
        { label: 'Decedent', value: 'BanDec0001', id: 2 },
        { label: 'Wire processing', value: 'BanWir0001', id: 3},
        { label: 'Demographic changes', value: 'BanDem0001', id: 4},
        { label: 'POA documents', value: 'BanPOA0001', id: 5}
    ];

    return depositDocTypes;
}