import React from 'react';

const BusySpinner = ({busy}) => {
    return (
        <>
            <div className='spinner-border' role='status' id='search-spinner' data-testid='search-spinner' style={busy}>
                <span className='sr-only'>Loading...</span>
            </div>
        </>
    );
}

export default BusySpinner;
