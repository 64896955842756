import React from "react";
import slm_logo from "../images/sallie-mae-logo.svg";


import Container from "@mui/material/Container";

const Header = () => {

  return (
    <>
      <Container maxWidth="lg">

        <div className="logo">
          <img src={slm_logo} alt="slm-logo" border="0" width='172.37px' height='67.33px' right='31px'/>
        </div>

      </Container>
    </>
  );
};

export default Header;
