import React from "react";
import PropTypes from "prop-types";
import { TextareaAutosize } from '@mui/base';
import '../App.css'

const TextInput = (props) => {

  const isLabelControlShow = ((typeof props.show === 'undefined' || props.show) ? true : false);
  let labelControl = "";
  let inputType = "";

  if (isLabelControlShow) {
    inputType = "text";
    labelControl = <label htmlFor={props.id} className="label-padding">{props.label}</label>
  } else {
    inputType = "hidden";
    labelControl = <label htmlFor={props.id} hidden>{props.label}</label>
  }

  if (props.showtextarea) {
    return (
      <>
        {labelControl}
        <TextareaAutosize
          id={props.id}
          onChange={props.onChange}
          name={props.name}
          className={"form-control " + props.classname}
          value={props.value}
          multiline="true"
          minRows={4}
          placeholder=""
          label=""
          variant="outlined"
          size="sm" 
        />
        {props.error && <div className="alert alert-danger">{props.error}</div>}
      </>
      
    );    
  }
  else {
    return (
      <>
        {labelControl}
        <input
          id={props.id}
          type={inputType}
          onChange={props.onChange}
          name={props.name}
          className={"form-control " + props.classname}
          value={props.value}
        />
        {props.error && <div className="alert alert-danger">{props.error}</div>}
      </>
      
    );
  }
}

TextInput.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string,
  error: PropTypes.string
};

TextInput.defaultProps = {
  error: ""
};


export default TextInput;

