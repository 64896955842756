import React from "react";
import {useState, useEffect} from 'react';
import { Link, useLocation } from "react-router-dom";
import 'react-dropdown/style.css';
import './App.css';
import UploadResultMessage from './common/UploadResultMessage'

const UploadResultPage = () =>  {

    const activeFontFamily = { font: "graphik-light"};
    const location = useLocation();
    const showUploadMore = (location.state.state.uploadresult === 'success');

    const uploadResultMessage = new Map( [["success", "Upload successful"], ["failure", "Upload failed"]] );

    return (
        <>
        <div align="left">
            <h1 style={activeFontFamily} >{uploadResultMessage.get(location.state.state.uploadresult)}</h1>
            <br></br>
            {/*<UploadResultMessage usertype={location.state.state.usertype} uploadresult={uploadResultMessage.get(location.state.state.uploadresult)}></UploadResultMessage>*/}
            <UploadResultMessage usertype={location.state.state.usertype} uploadresult={location.state.state.uploadresult}></UploadResultMessage>
            { showUploadMore ? <UploadMoreLinkBtn /> : null }
        </div>          
        </>
    );
}

const UploadMoreLinkBtn = () => (
    <div  style={{'display': 'flex', 'flexDirection': 'row', 'alignSelf': 'flex-end'}}>
        <Link to={"/"} className="btn custom-btn-primary small">
            Upload more documents
        </Link>
    </div>
);
  
export default UploadResultPage;
  