import React from 'react';
import { useState, useEffect } from 'react';
import { Link as ReactRouterLink, useLocation } from 'react-router-dom';
import { Link as TextLink } from '@mui/joy';
import Typography from '@mui/joy/Typography';
import TextInput from "../common/TextInput";
import {checkDepositFormErrors} from "../../utils/Validation";
import { formatPhoneNumber} from "../../utils/Formatting"

const THREE = 3;
const FOUR = 4;
const FIVE = 5;
const SIX = 6;
const NINE = 9;

const DepositCustomerPage = () => {
  const location = useLocation();
  const [errors, setErrors] = useState({});

  const divStyle = {
    overflowY: 'auto',
    fontSize: '12px'
  };

  // Deposit Customer Page
  const [depositCustomerPage, setDepositCustomerPage] = useState(
    {
      firstname: "",
      lastname: "",
      socialsecuritynumber: "",
      accountnumber: "",
      emailaddress: "",
      phonenumber: ""
    }
  );


  function formatSSN(value) {
    // if input value is falsy eg if the user deletes the input, then just return
    if (!value) {
      return value;
    }
    // clean the input for any non-digit values.
    const ssn = value.replace(/[^\d]/g, "");

    // ssnLength is used to know when to apply our formatting for the ssn
    const ssnLength = ssn.length;
    
    // we need to return the value with no formatting if its less than four digits
    if (ssnLength < FOUR) {
      return ssn;
    }
    // if ssnLength is greater than 4 and less than 6 we start to return
    // the formatted number
    if (ssnLength < SIX) {
      return `${ssn.slice(0, THREE)}-${ssn.slice(THREE)}`;
    }

    // finally, if the ssnLength is greater than 6, we add the last
    // bit of formatting and return it.
    const result_ssn = `${ssn.slice(0, THREE)}-${ssn.slice(THREE, FIVE)}-${ssn.slice(FIVE, NINE)}`;
    return result_ssn;
  }


  function formatAccountNumber(value) {
    // if input value is falsy eg if the user deletes the input, then just return
    if (!value) {
      return value;
    }
    // clean the input for any non-digit values.
    const accountNumber = value.replace(/[^\d]/g, "");
    return accountNumber;
  }


  useEffect(() => {
    // const _depositInfo = (location.state === undefined ? null : location.state.schoolinfo);
    // from the location object - if this page was displayed by clicking on Back from Upload page
    const _depositCustomerPage = (location.state === undefined ? null : location.state.depositCustomerPage)

    if (_depositCustomerPage) {
      setDepositCustomerPage(_depositCustomerPage);
    }
  }, [location.state]);

  const isFormValid = () => {
    const _formErrors = checkDepositFormErrors(depositCustomerPage);
    setErrors(_formErrors);
    return Object.keys(_formErrors).length === 0;
  }

  const resetError = (fieldName) => {
    const _errors = { ...errors };
    delete _errors[fieldName];
    setErrors(_errors);
  }

  const handleClick = (e) => {
    const validStatus = isFormValid();

    if (!validStatus) {
      e.preventDefault(); // prevent user from navigating to next page
    }
  }


  const handleDepositCustomerPageChange = ({ target }) => {
    // Reset error if there is one
    if (errors[target.name]) {
      resetError(target.name);
    }

    // Store the keyed in value in state variable
    setDepositCustomerPage({
      ...depositCustomerPage,
      [target.name]: target.value
    });
  }

  const handlePhoneNumberChange = ({ target }) => {
    // Reset error if there is one
    if (errors[target.name]) {
      resetError(target.name);
    }

    // Store the keyed in value in state variable
    setDepositCustomerPage({
      ...depositCustomerPage,
      [target.name]: formatPhoneNumber(target.value)
    });
  }

  const handleAccountNumberChange = ({ target }) => {
    // Reset error if there is one
    if (errors[target.name]) {
      resetError(target.name);
    }

    // Store the keyed in value in state variable
    setDepositCustomerPage({
      ...depositCustomerPage,
      [target.name]: formatAccountNumber(target.value)
    });
  }
  

  return (
    <>
      <div style={divStyle}>
        <h1>Secure Document Upload</h1>
        <h2>Deposit customer</h2>

        <Typography>
          As you input your personal information, we want you to know that we take
          protection of your data seriously. To learn more about how we collect,
          use, retain and disclose your personal information, please see our&nbsp;
          <TextLink href="https://www.salliemae.com/legal/privacy">Privacy Policy</TextLink>
          .
          <br></br><br></br>
        </Typography>
        
        <Typography>
          Please enter the Social Security number of the customer associated to
          the documentation being provided.
        </Typography>

        <h3>Your information</h3>

        <TextInput
          id="firstname"
          label="Your first name"
          name="firstname"
          onChange={handleDepositCustomerPageChange}
          value={depositCustomerPage.firstname}
          error={errors.firstname}
          classname="textinput"
        />

        <TextInput
          id="lastname"
          label="Your last name"
          name="lastname"
          onChange={handleDepositCustomerPageChange}
          value={depositCustomerPage.lastname}
          error={errors.lastname}
          classname="textinput"
        />

        <TextInput
          id="socialsecuritynumber"
          label="Social Security Number"
          name="socialsecuritynumber"
          onChange={handleDepositCustomerPageChange}
          value = {formatSSN(depositCustomerPage.socialsecuritynumber)}
          error={errors.socialsecuritynumber}
          classname="textinput"
        />

        <TextInput
          id="accountnumber"
          label="Account number (optional)"
          name="accountnumber"
          onChange={handleAccountNumberChange}
          value= {depositCustomerPage.accountnumber}
          error={errors.accountnumber}
          classname="textinput"
        />

        <TextInput
          id="emailaddress"
          label="Your email address (optional)"
          name="emailaddress"
          onChange={handleDepositCustomerPageChange}
          value={depositCustomerPage.emailaddress}
          error={errors.emailaddress}
          classname="textinput"
          maxLength={320}
        />

        <TextInput
          id="phonenumber"
          label="Your phone number (optional)"
          name="phonenumber"
          onChange={handlePhoneNumberChange}
          value={depositCustomerPage.phonenumber}
          error={errors.phonenumber}
          classname="textinput-sml"
        />
        <p />

        <div style={{ 'display': 'flex', 'flexDirection': 'row', 'alignSelf': 'flex-end' }}>
            <ReactRouterLink to={"/"} className="btn custom-btn-secondary">
              Back
            </ReactRouterLink>

            <ReactRouterLink to={
              {
                pathname: "./DepositUpload",
                state: {
                  // depositinfo: depositInfo,
                  depositCustomerPage: depositCustomerPage
                }
              }
            }

              onClick={handleClick}
              className="btn custom-btn-primary">
              Continue
            </ReactRouterLink>
        </div>
      </div>
    </>
  );
};


export default DepositCustomerPage;

const fontFam = "Graphik, Calibri";
const styles = {
  pagehdr: () => ({
    fontFamily: fontFam,
    fontWeight: "semibold",
    fontSize: "36px",
    top: "134px",
    left: "159px",
    width: "445px",
    height: "36px",
    position: "relative",
    textAlign: "left",
    letterSpacing: "0px",
    color: "#333333",
  }),
  pagedesc: () => ({
    fontFamily: fontFam,
    fontWeight: "regular",
    fontSize: "20px",
    top: "200px",
    width: "904px",
    height: "20px",
    left: "159px",
    position: "relative",
    textAlign: "left",
    letterSpacing: "0px",
    color: "#000000",
  }),
  ddlbdesc: () => ({
    fontFamily: fontFam,
    fontWeight: "semibold",
    fontSize: "26px",
    top: "260px",
    width: "268px",
    height: "26px",
    left: "159px",
    position: "relative",
    textAlign: "left",
    letterSpacing: "0px",
    color: "#333333",
  }),
  ddlb: () => ({
    fontFamily: "Graphik-light, Calibri",
    fontWeight: "regular",
    fontSize: "18px",
    top: "306px",
    width: "424px",
    height: "44px",
    left: "159px",
    border: "1px solid #B6B7BA",
    borderRadius: "2px",
    position: "relative",
    textAlign: "left",
    color: "#888888",
    characterSpacing: 0,
    lineSpacing: 40,
  }),
  continuebtn: () => ({
    top: "410px",
    left: "159px",
    width: "160px",
    height: "45px",
    /* UI Properties */
    background: "#D1D1D3 0% 0% no-repeat padding-box",
    borderRadius: "23px",
    opacity: 1,
    /*display: 'flex', 
      flexDirection: 'row', 
      alignSelf: 'flex-end',*/
    /* UI Properties */
    fontFamily: "Graphik",
    fontWeight: "semibold",
    textAlign: "middle middle",
    fontSize: "14px",
    letterSpacing: "0px",
    // fontColor: '#5E5E5E',
    textDecoration: "none",
    color: "#fff"
  }),
  continuebtn2: () => ({
    top: "410px",
    left: "159px",
    width: "160px",
    height: "45px",
    /* UI Properties */
    background: "#D1D1D3 0% 0% no-repeat padding-box",
    borderRadius: "23px",
    opacity: 1,
    fontFamily: "Graphik",
    fontWeight: "semibold",
    textAlign: "middle middle",
    fontSize: "14px",
    letterSpacing: "0px",
    fontcolor: "white",
    color: "white",
    borderColor: "#1A4475",
    borderWidth: "0",
    textalign: "center",
    textDecoration: "none"
  }),
};
