import React from "react";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import { getUserTypeOptions } from "../api/userType";
import Dropdown from "react-dropdown";
import "react-dropdown/style.css";
import "./App.css";

const SelectionPage = () => {
  const number97 = 97;
  const number98 = 98;
  const number99 = 99;
  const [userTypeOptions, setUserTypeOptions] = useState(null);
  const [selectedUserType, setSelectedUserType] = useState({
    value: 1,
    label: "One",
  });
  const [toPageLink, setToPageLink] = useState("");
  const [noUserTypeError, setNoUserTypeError] = useState("");

  const toPage = (optionSelected) => {
    

    if (optionSelected.value === number98) {
      return "/schoolofficialinfo/prospect";
    } else if (optionSelected.value === number99) {
      return "/schoolofficialinfo/existing";
    } else if (optionSelected.value === number97) {
      return "/RetailBanking/DepositCustomer";
    } else {
      return "";
    }
  };

  // Event handlers
  const handleClick = (event) => {
    if (selectedUserType.value === 1) {
      event.preventDefault();
      setNoUserTypeError("No user type selected.");
      return;
    }
  };

  const handleChangeSelectedUserType = (option) => {
    setNoUserTypeError("");
    setSelectedUserType(option);

  };

  // Load the userTypes and set the To attribute on Link component
  useEffect(() => {
    setUserTypeOptions(getUserTypeOptions());
  }, []);

  useEffect(() => {
    setToPageLink(toPage(selectedUserType));
  }, [selectedUserType]);

  return (
    <>
      <h1>Secure Document Upload</h1>
      <p>
        This page will let you upload and send us images of requested
        documents.
      </p>
      <h3>Who is uploading?</h3>
      <div className="ddlb">
        <Dropdown
          options={userTypeOptions}
          onChange={handleChangeSelectedUserType}
          placeholder="Select user type"
        />
        {noUserTypeError.length > 0 && (
          <div className="alert alert-danger">{noUserTypeError}</div>
        )}
      </div>
      <div className="btn-group">
        <div className="form-group">
          <Link
            to={toPageLink}
            className="btn custom-btn-primary"
            href="/"
            onClick={handleClick}
          >
            Continue
          </Link>
        </div>
      </div>
    </>
  );
};


export default SelectionPage;
