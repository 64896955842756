const THREE = 3;
const FOUR = 4;
const SIX = 6;
const SEVEN = 7;
const EIGHT = 8;
const TEN = 10;

const formatPhoneNumber = (value) => {
    // if input value is falsy eg if the user deletes the input, then just return
    if (!value) {
      return value;
    }
    // clean the input for any non-digit values.
    const input = value.replace(/[^\d]/g, "");

    // Trim the remaining input to ten characters, to preserve phone number format
    const phoneNumber = input.substring(0,TEN);

    // Based upon the length of the string, we add formatting as necessary
    const phoneLength = phoneNumber.length;
    
    if(phoneLength === 0){
      return phoneNumber;
    }else if(phoneLength < FOUR){
            return '(' + phoneNumber;
    }else if(phoneLength < SEVEN){
            return'(' + phoneNumber.substring(0,THREE) + ') ' + phoneNumber.substring(THREE,SIX);
    }else{
            return '(' + phoneNumber.substring(0,THREE) + ') ' + phoneNumber.substring(THREE,SIX) + '-' + phoneNumber.substring(SIX,TEN);
    }

  }

  const formatSchoolCode = (value) => {
    // if input value is falsy eg if the user deletes the input, then just return
    if (!value) {
      return value;
    }
    // Get the length of input before clearing out non-digits
    const inputLength = value.length;

    // clean the input for any non-digit values.
    const input = value.replace(/[^\d]/g, "");

    // Trim the remaining input to eight characters, to preserve school code format
    const schoolCode = input.substring(0,EIGHT);

    // Based upon the length of the string, we add formatting as necessary
    const schoolLength = schoolCode.length;
    
    if (schoolLength === SIX && inputLength !== SEVEN){
      return schoolCode + "-00";
    }
    else if (schoolLength >= SEVEN){
            return schoolCode.substring(0,SIX) + '-' + schoolCode.substring(SIX,EIGHT);
    }
    else
    {
      return schoolCode;
    }

  }

  export {formatPhoneNumber, formatSchoolCode};

