import {StrictMode} from "react";
import { createRoot } from 'react-dom/client';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle';

import Header from './components/Header';
import App from './components/App';
import Footer from './components/Footer';
import { BrowserRouter as Router } from "react-router-dom";
import reportWebVitals from './reportWebVitals';


const rootElement = document.getElementById('root');
const root = createRoot(rootElement);

root.render(
  <StrictMode>
    <Router>
    <div className="wrapper">
      <Header />
      <App />
      <Footer />
    </div>
    </Router>
  </StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
