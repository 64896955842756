// import fetchMock from 'jest-fetch-mock'

const callRetailUploadApi = async formData => {

    const response =
        await fetch(formData[1], {
            method: 'POST',
            mode: 'cors',
            catch: 'no-cache',
            credentials: 'same-origin',
            redirect: 'follow',
            referrerPolicy: 'no-referrer',
            body: formData[0]
    });

    if (response.ok) {
        return response.json(); // parses JSON response into native JavaScript objects
    }

    const errorMessage = await response.text();
    throw new Error(errorMessage);
}


const appendToFormDepositData = (uploadApiUrl, uploadInfo, uploadFiles, comments) => {

    const NINE = 9;
    const formData = new FormData();
    for (let i=0;i< uploadFiles.length; i++) {
        if (uploadFiles[i].filetoupload) {
            const filename = 'UploadFileName' + i;
            formData.append(filename, uploadFiles[i].filetoupload);
            formData.append('RetailDocumentCategoryCode', uploadFiles[i].doctype);
        }
    }

    formData.append('Comments', comments);

    formData.append('FirstName', uploadInfo.depositCustomerPage.firstname);
    formData.append('LastName', uploadInfo.depositCustomerPage.lastname);
    formData.append('SSN', uploadInfo.depositCustomerPage.socialsecuritynumber.replace(/-/g, "").slice(0, NINE));
    formData.append('AccountID', uploadInfo.depositCustomerPage.accountnumber);
    formData.append('PhoneNumber', uploadInfo.depositCustomerPage.phonenumber);
    formData.append('EmailAddress', uploadInfo.depositCustomerPage.emailaddress);

    return [formData, uploadApiUrl];
}


export {callRetailUploadApi, appendToFormDepositData};
