import React from "react";
import {useState, useEffect, useMemo} from 'react';
import { getDocumentTypes } from '../../api/documentTypes';

import Box from '@mui/joy/Box';
import Grid from '@mui/joy/Grid';
import Typography from '@mui/joy/Typography';

import Tooltip from '@mui/material/Tooltip';

import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import IconButton from '@mui/joy/IconButton';


const SchoolDocumentType = (props) => {

 const number7 = 7;
 const number9 = 9;
// store in state variable
  const [userType, setUserType] = useState(props.usertype);

// make sure the component does not rerender when arrays don't change
  const documentTypes = useMemo(() => getDocumentTypes(userType), [userType]);

  const docTypesColumn1 = useMemo(() => 
                                      {
                                        let _documentTypesColumn1 = null;
                                        
                                        if (userType === 'existing')
                                         { _documentTypesColumn1 = (documentTypes.length > number7 ? documentTypes.slice(0, number7) : []);}
                                        else if (userType === 'prospect')
                                          {_documentTypesColumn1 = (documentTypes.length > number9 ? documentTypes.slice(0, number9) : []);}
                                        else{
                                          _documentTypesColumn1 = [];
                                        }
                                        return _documentTypesColumn1;
                                      }, 
                                      [userType, documentTypes]
                                  );

  const docTypesColumn2 = useMemo(() => 
                                      {
                                        let _documentTypesColumn2 = null;
                                        
                                        if (userType === 'existing')
                                         { _documentTypesColumn2 = (documentTypes.length > number7 ? documentTypes.slice(number7) : []);}
                                        else if (userType === 'prospect')
                                          {_documentTypesColumn2 = (documentTypes.length > number9 ? documentTypes.slice(number9) : []);}
                                          else{
                                            _documentTypesColumn2 = [];
                                          }
                                        return _documentTypesColumn2;
                                      },
                                      [userType, documentTypes]
                                  );
  
  useEffect(() => {
    setUserType(props.usertype);
  }, [props.usertype]);


  return (
    <Box       
      sx={{
        flexGrow: 1,
        display: 'flex',
        justifyContent: 'left',
        gap: 6,
        textAlign: 'left'

      }}
    >
      <Grid direction="column" container justifyContent='left' spacing={1}>
          { 
            docTypesColumn1.map((doctype, i) => (  
              <GridItem key={"key1-" + doctype.id} props={
                                            {
                                              doctype: doctype,
                                              arrayidx: {i}
                                            }
                                      }
              >
              </GridItem>
            ))
          }
      </Grid>
      <Grid direction="column" justifyContent='flex-start' container spacing={1}>
          { 
            docTypesColumn2.map((doctype, i) => (  
              <GridItem key={"key2-" + doctype.id} props={
                                            { 
                                              doctype: doctype, 
                                              arrayidx: {i} 
                                            }
                                       }
              >
              </GridItem>
            ))
          }
      </Grid>
    </Box>
  );

} 

export default SchoolDocumentType;

const GridItem = (props) => {
  

  // Note: These values are stored in const just for convenience. 
  // The only value that will change is the open/close toggle
  const docType = props.props.doctype;
  const idx = props.props.arrayidx;

  const [open, setOpen] = useState(false);

  const handleClose = () =>  { 
    setOpen(false);
  }

  const handleToggle = () => { 
    setOpen(!open); 
  }

  return (
    // From 0 to 600px wide (smart-phones), I take up 12 columns, or the whole device width!
    // From 600-690px wide (tablets), I take up 6 out of 12 columns, so 2 columns fit the screen.
    // From 960px wide and above, I take up 25% of the device (3/12), so 4 columns fit the screen.
    <Grid item xs key={"key"+idx} justifyContent="flex-start">
        {docType.id + ". " + docType.label}
        <Tooltip 
          popperprops={{disablePortal: true}} 
          onClose={handleClose}
          open={open}
          disableFocusListener 
          disableHoverListener 
          disableTouchListener 
          title={
                  <>
                    <Typography  >{docType.description}</Typography>
                  </>
                }

          placement="right-end"
          arrow={true}
        >
          <IconButton aria-label="Info" size="sm" color="green" onClick={handleToggle}>
            <InfoOutlinedIcon fontSize="small" />
          </IconButton>
        </Tooltip>
    </Grid>
  );
}

