import { Route, Switch } from 'react-router-dom';

import SelectionPage from './SelectionPage'; 
import SchoolOfficialInfoPage from './SchoolOfficialInfoPage';
import ExistingSchoolOfficialUploadPage from './ExistingSchoolOfficialUpload';
import ProspectSchoolOfficialUploadPage from './ProspectSchoolOfficialUpload';
import DepositUploadPage from './RetailBanking/DepositUpload';
import DepositCustomerPage from './RetailBanking/DepositCustomer';
import DepositUploadResultPage from './RetailBanking/DepositUploadResultPage';
import UploadResultPage from './UploadResultPage';
import Container from '@mui/material/Container';

function App() {

  return (
    <Container maxWidth="lg">
    <div className="App">
      <main>
        <Switch>
          <Route path='/' exact component={SelectionPage} />
          <Route path='/schoolofficialinfo/:usertype' exact component={SchoolOfficialInfoPage} />
          <Route path='/schoolofficialinfo/existing/upload' exact component={ExistingSchoolOfficialUploadPage} />
          <Route path='/schoolofficialinfo/prospect/upload' component={ProspectSchoolOfficialUploadPage} />
          <Route path='/uploadresult' component={UploadResultPage} />
          <Route path='/RetailBanking/DepositCustomer' component={DepositCustomerPage} />
          <Route path='/RetailBanking/DepositUpload' component={DepositUploadPage} />
          <Route path='/RetailBanking/DepositUploadResultPage' component={DepositUploadResultPage} />
        </Switch>
      </main>
    </div>
    </Container>
  );
}

export default App;
